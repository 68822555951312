import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { routes } from 'utils/routes';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { FormattedMessage } from 'react-intl';
import { getZendeskRequestUrl } from '@customer-frontend/utils';

export const AwaitingAnswersCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const helpLink = getZendeskRequestUrl({ problemType: consultation.type });

  return (
    <StatusLayout {...layoutProps} testId="awaiting-answers-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      {!asyncConsultsEnabled && (
        <Typography size="medium-paragraph">
          {consultation.doctor?.shortClinicianName} has sent you a message.
        </Typography>
      )}
      {asyncConsultsEnabled && (
        <>
          <Typography size="medium-paragraph" isBold>
            <FormattedMessage
              defaultMessage="{shortClinicianName} sent you a message."
              values={{
                shortClinicianName: consultation.doctor?.shortClinicianName,
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Open your consult chat to read it and reply." />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
              values={{
                a: (chunks) => (
                  <a href={helpLink} className="text-link">
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
          </Typography>
        </>
      )}
      <Button
        eventElementName="treatmentPageAwaitingAnswersCardButton"
        onClick={() => {
          if (consultation.chatThread) {
            history.push(routes.consultation.chat(consultation.id));
          } else {
            history.push(routes.consultation.review(consultation.id));
          }
        }}
      >
        <FormattedMessage defaultMessage="Open consult chat" />
      </Button>
    </StatusLayout>
  );
};
