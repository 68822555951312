import {
  ClinicianType,
  ConsultationStage,
  ConsultationStatus,
  Maybe,
  ProblemType,
  TreatmentStatus,
} from '@customer-frontend/graphql-types';
import {
  clinicianNoun,
  getInlineReadableProblemType,
} from '@customer-frontend/utils';
import { ActiveTreatmentCardContent } from '../active-treatment';
import { PausedTreatmentCardContent } from '../paused-treatment';
import { FollowUpTreatmentCardContent } from '../follow-up';
import { ReviewTreatmentContent } from '../review-treatment';
import { AwaitingAnswersCardContent } from '../awaiting-answers';
import { ProcessingOrderTreatmentCardContent } from '../processing-order';
import { CreatedTreatmentCardContent } from '../created-treatment';
import { getConsultationStatusContent } from './consultation-status';
import { StatusConfigReturnType } from '../types';
import { CanceledCardContent } from '../canceled';

export const getTreatmentStatusContent = (
  consultation: {
    id: string;
    status: ConsultationStatus;
    type: ProblemType;
    stage: ConsultationStage;
    isApproved?: boolean | null;
    doctor?: {
      fullName?: Maybe<string>;
      fullClinicianName: string;
      shortClinicianName: string;
      provider?: {
        clinicianType: ClinicianType;
      } | null;
    } | null;
    reminders?:
      | {
          id: string;
          canceled: boolean;
          doneAt?: string;
          scheduledAt: string;
        }[]
      | null;
    allowPatientToSendMessageToDoctor: boolean;
    treatment?: { status: TreatmentStatus } | null;
  },
  asyncConsultsEnabled: boolean,
): Omit<StatusConfigReturnType, 'problemType'> | null => {
  const isAwaitingFollowupSurveyStart =
    consultation.status === 'AWAITING_SURVEY' &&
    consultation.stage === 'FOLLOW_UP';
  const hasTreatment = !!consultation?.treatment;
  const clinicianType = consultation.doctor?.provider?.clinicianType;

  if (consultation.status === 'CANCELED') {
    return {
      title: 'Cancelled',
      component: CanceledCardContent,
    };
  }

  if (hasTreatment || isAwaitingFollowupSurveyStart) {
    if (consultation.status === 'AWAITING_ANSWERS') {
      return {
        title: asyncConsultsEnabled
          ? null
          : clinicianType
          ? `Your ${clinicianNoun(clinicianType)} has some questions for you`
          : 'You have a message',
        component: AwaitingAnswersCardContent,
      };
    }

    switch (consultation.treatment?.status) {
      case 'CREATED':
        return {
          title: 'Your treatment plan is ready',
          component: CreatedTreatmentCardContent,
        };
      case 'FOLLOW_UP':
        return {
          title: `Your ${getInlineReadableProblemType(
            consultation.type,
          )} treatment plan`,
          component: FollowUpTreatmentCardContent,
        };
      case 'PROCESSING_PAYMENT':
      case 'PAYMENT_FAILED':
      case 'PROCESSING_ORDER':
        return {
          title: 'Your order is on the way',
          component: ProcessingOrderTreatmentCardContent,
        };
      case 'ACTIVE':
        return {
          title: `Your ${getInlineReadableProblemType(
            consultation.type,
          )} treatment plan`,
          component: ActiveTreatmentCardContent,
        };
      case 'PAUSED':
        return {
          title: 'Your plan is paused',
          component: PausedTreatmentCardContent,
        };
      case 'REVIEW':
        return {
          title: '',
          component: ReviewTreatmentContent,
        };
      case 'CANCELED':
        if (typeof consultation.isApproved === 'boolean') {
          return {
            title: 'Cancelled',
            component: CanceledCardContent,
          };
        }
      // eslint-disable-next-line no-fallthrough
      default:
        return getConsultationStatusContent(consultation, asyncConsultsEnabled);
    }
  } else {
    return getConsultationStatusContent(consultation, asyncConsultsEnabled);
  }
};
