import React, { type ReactElement } from 'react';
import {
  PurchaseCardConsultationFragment,
  PurchaseCardPurchaseFragment,
} from '@customer-frontend/graphql-types';
import { PurchaseActiveCard } from './purchase-active-card';
import { PurchaseProcessingCard } from './purchase-processing-card';
import { PurchasePausedCard } from './purchase-paused-card';
import { PurchaseCancelledCard } from './purchase-cancelled-card';
import { PurchaseCompletedCard } from './purchase-completed-card';
import { TreatmentReviewAwaitingSurveyCard } from '../consultation/components/treatment-review-awaiting-survey-card';
import { OrderDetails, usePurchaseCardContent } from '@customer-frontend/order';
import { useIntl } from 'react-intl';
import { TreatmentReviewAwaitingDoctorCard } from '../consultation/components/treatment-review-awaiting-doctor-card';
import { DoctorAssignedCard } from '../consultation/components/doctor-assigned-card';
import { usePresciberNameFromDoctor } from '@customer-frontend/doctor';
import { AwaitingResultsCard } from '../consultation/components/awaiting-results';
import { PurchaseAwaitingPaymentCard } from './purchase-awaiting-payment';
import { useConsultationFlowConfig } from '@customer-frontend/config';
import { RefetchQueriesInclude, gql } from '@apollo/client';
import { UseCustomTreatmentDetails } from '../treatment';

type PurchaseCardProps = {
  purchase: PurchaseCardPurchaseFragment;
  isBeingTreatedForWeight: boolean;
  consultation?: PurchaseCardConsultationFragment;
  useCustomTreatmentDetails?: UseCustomTreatmentDetails;
  refetchQueries?: RefetchQueriesInclude;
  routes: {
    consultation: {
      collectAddress: (id: string) => string;
      collectMedicare: (id: string) => string;
      phoneCall: (id: string) => string;
      followUp: (id: string) => string;
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
    };
    offering: {
      plan: (id: string) => string;
    };
    weightLoss: {
      dashboard: string;
    };
  };
};

export function PurchaseCard({
  purchase,
  isBeingTreatedForWeight,
  consultation,
  refetchQueries,
  routes,
}: PurchaseCardProps): ReactElement | null {
  const { formatMessage } = useIntl();

  let refillsLeft: number | undefined;
  if (purchase.orderTimeline?.length !== 0) {
    refillsLeft = purchase.orderTimeline?.filter(
      ({ status }) => status === 'UPCOMING',
    ).length;
  }

  const doctorName = usePresciberNameFromDoctor(consultation?.doctor);
  const consultConfig = useConsultationFlowConfig(consultation?.type);
  const isSyncConsult = !!(
    consultation?.requiresPrescriberCall &&
    consultConfig?.requiresPrescriberBooking
  );
  const purchaseCardContent = usePurchaseCardContent({ purchase });
  const orderDetails = purchaseCardContent && (
    <OrderDetails {...purchaseCardContent} />
  );

  if (!purchase.offering) {
    return null;
  }

  if (consultation && consultation.stage === 'REVIEW') {
    if (purchase.status === 'PAUSED') {
      if (consultation.status === 'AWAITING_SURVEY') {
        return (
          <TreatmentReviewAwaitingSurveyCard
            consultationId={consultation.id}
            hasActiveWeightTreatment={isBeingTreatedForWeight}
            problemType={consultation?.type}
            quizApplicationSubmitted={
              !!consultation.quizApplication?.submittedAt
            }
            routes={routes}
          >
            <OrderDetails
              treatmentName={purchase.offering.friendlyName}
              status={formatMessage({
                defaultMessage: 'In review',
                description:
                  'Text status on the profile to show that the consultation is in review',
              })}
              refillsLeft={refillsLeft}
              orderItems={[]}
            />
          </TreatmentReviewAwaitingSurveyCard>
        );
      }

      if (consultation.status === 'AWAITING_DOCTOR') {
        return (
          <TreatmentReviewAwaitingDoctorCard
            consultationId={consultation.id}
            hasActiveWeightTreatment={isBeingTreatedForWeight}
            hasChatThread={!!consultation.chatThread}
            routes={routes}
          >
            <OrderDetails
              treatmentName={purchase.offering.friendlyName}
              status={formatMessage({
                defaultMessage: 'Follow up pending',
                description:
                  'Text status on the profile to show that the consultation waiting for a follow up to be completed',
              })}
              refillsLeft={refillsLeft}
              orderItems={[]}
            />
          </TreatmentReviewAwaitingDoctorCard>
        );
      }
      if (consultation.status === 'DOCTOR_ASSIGNED') {
        return (
          <DoctorAssignedCard
            consultationId={consultation.id}
            doctorName={doctorName}
            allowPatientToSendMessageToDoctor={
              consultation.allowPatientToSendMessageToDoctor
            }
            isSyncConsult={isSyncConsult}
            hasChatThread={!!consultation.chatThread}
            routes={routes}
            problemType={consultation.type}
          />
        );
      }
      if (consultation.status === 'AWAITING_RESULTS') {
        return (
          <AwaitingResultsCard
            pathologyRequests={consultation.pathologyRequests}
          />
        );
      }
    }
  }

  switch (purchase.status) {
    case 'AWAITING_PAYMENT':
      return (
        <PurchaseAwaitingPaymentCard>
          {orderDetails}
        </PurchaseAwaitingPaymentCard>
      );
    case 'AWAITING_VALID_SCRIPTS':
      return (
        <PurchaseProcessingCard purchase={purchase} routes={routes}>
          {orderDetails}
        </PurchaseProcessingCard>
      );
    case 'ACTIVE': {
      const rxCscIsPaused = purchase.contexts?.some(
        (c) =>
          c.status === 'PAUSED' &&
          c.sequence?.products?.some((p) => p.productType === 'RX'),
      );
      if (rxCscIsPaused) {
        return (
          <PurchasePausedCard
            purchase={purchase}
            refetchQueries={refetchQueries}
          >
            {orderDetails}
          </PurchasePausedCard>
        );
      }

      return (
        <PurchaseActiveCard purchase={purchase} routes={routes}>
          {orderDetails}
        </PurchaseActiveCard>
      );
    }
    case 'PAUSED':
      return (
        <PurchasePausedCard purchase={purchase} refetchQueries={refetchQueries}>
          {orderDetails}
        </PurchasePausedCard>
      );
    case 'CANCELLED':
      return <PurchaseCancelledCard />;
    case 'COMPLETED':
      return (
        <PurchaseCompletedCard purchase={purchase} routes={routes}>
          {orderDetails}
        </PurchaseCompletedCard>
      );
    default:
      return null;
  }
}

PurchaseCard.fragment = gql`
  fragment PurchaseCardConsultation on Consultation {
    id
    type
    stage
    status
    requiresPrescriberCall
    allowPatientToSendMessageToDoctor
    doctor {
      id
      fullName
      fullClinicianName
    }
    quizApplication {
      id
      submittedAt
    }
    chatThread {
      id
    }
    pathologyRequests {
      id
      ...AwaitingResultsCardPathologyRequest
    }
  }

  fragment PurchaseCardPurchase on Purchase {
    id
    status
    orderTimeline {
      id
      status
    }
    offering {
      id
      friendlyName
    }
    ...UsePurchaseCardContentPurchase
    ...PurchaseProcessingCardPurchase
    ...PurchaseCompletedCardPurchase
    ...PurchaseActiveCardPurchase
    ...PurchasePausedCardPurchase
  }

  ${usePurchaseCardContent.fragment}
  ${AwaitingResultsCard.fragment}
  ${PurchaseProcessingCard.fragment}
  ${PurchaseCompletedCard.fragment}
  ${PurchaseActiveCard.fragment}
  ${PurchasePausedCard.fragment}
`;
