import React from 'react';
import { useHistory } from 'react-router-dom';
import { routes } from 'utils/routes';
import { Button, Typography } from '@eucalyptusvc/design-system';
import { StatusCardContentProps } from './types';
import { SkinGoalsSummary, StatusLayout } from './components';
import { formatDoctorName, TitleType } from '@customer-frontend/doctor';
import { NurseInfo } from './nurse-info';
import { isNursePractitionerEnabled } from 'utils/misc';
import { FormattedMessage } from 'react-intl';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { getZendeskRequestUrl } from '@customer-frontend/utils';

export const DoctorAssignedCardContent = ({
  consultation,
  personalisationInput,
  ...layoutProps
}: StatusCardContentProps): React.ReactElement => {
  const history = useHistory();
  const { doctor, type } = consultation;
  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const helpLink = getZendeskRequestUrl({ problemType: type });

  const drName = doctor
    ? formatDoctorName(doctor, TitleType.DOCTOR)
    : 'your practitioner';

  return (
    <StatusLayout {...layoutProps} testId="doctor-assigned-consultation-card">
      <SkinGoalsSummary skinGoals={personalisationInput.mappedSkinGoals} />
      {!asyncConsultsEnabled && (
        <Typography size="medium-paragraph">
          Your information is currently being reviewed by {drName}. We&apos;ll
          be in touch with you soon.
        </Typography>
      )}
      {asyncConsultsEnabled && (
        <>
          <Typography size="medium-paragraph" isBold>
            <FormattedMessage
              defaultMessage="{drName} is reviewing your information and will be in touch with you soon."
              values={{
                drName,
              }}
            />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage defaultMessage="If you have questions about your treatment, please send them in the chat. Your practitioner will answer them during your consult." />
          </Typography>
          <Typography size="medium-paragraph">
            <FormattedMessage
              defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
              values={{
                a: (chunks) => (
                  <a href={helpLink} className="text-link">
                    {chunks}
                  </a>
                ),
              }}
            />
          </Typography>
          {consultation.stage === 'REVIEW' && (
            <Typography size="medium-paragraph">
              <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
            </Typography>
          )}
        </>
      )}
      {consultation.stage === 'FOLLOW_UP' &&
        isNursePractitionerEnabled(layoutProps.problemType) && <NurseInfo />}
      {consultation.allowPatientToSendMessageToDoctor &&
        (consultation.chatThread?.id ? (
          <Button
            onClick={() => {
              history.push(routes.consultation.chat(consultation.id));
            }}
          >
            <FormattedMessage defaultMessage="Open consult chat" />
          </Button>
        ) : (
          <Button
            eventElementName="treatmentPageViewConsultButton"
            onClick={() => {
              history.push(routes.consultation.review(consultation.id));
            }}
          >
            View consult
          </Button>
        ))}
    </StatusLayout>
  );
};
