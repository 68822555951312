import React from 'react';
import { getTreatmentStatusContent } from './utils/treatment-status';
import { UserSkinProfile } from 'utils/personalisation';
import { StatusCardContentConsultation } from './types';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';

export const ConsultationStatusCard = (props: {
  consultation: StatusCardContentConsultation;
  userEmail: string | undefined;
  personalisationInput: UserSkinProfile;
}): React.ReactElement | null => {
  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );
  const statusContent = getTreatmentStatusContent(
    props.consultation,
    asyncConsultsEnabled,
  );

  if (!statusContent) {
    return null;
  }

  const { component: ContentComponent, ...statusProps } = statusContent;

  return (
    <ContentComponent
      {...props}
      {...statusProps}
      problemType={props.consultation.type}
    />
  );
};
