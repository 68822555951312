import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { ConsultationCardContentView } from './consultation-card-content-view';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { Typography } from '@eucalyptusvc/design-system';
import { getZendeskRequestUrl } from '@customer-frontend/utils';
import { ProblemType } from '@customer-frontend/graphql-types';

export const AwaitingAnswersCard: React.FC<{
  consultationId: string;
  doctorName: string;
  hasChatThread: boolean;
  routes: {
    consultation: {
      chat: (id: string, loadAtTop?: boolean) => string;
      review: (id: string) => string;
    };
  };
  problemType: ProblemType;
}> = ({ consultationId, doctorName, hasChatThread, routes, problemType }) => {
  const buttonKey = 'awaitingAnswersButton';
  const history = useHistory();

  const asyncConsultsEnabled = useFeatureFlagBoolean(
    'FF_ASYNC_CONSULTS_ENABLED',
  );

  const helpLink = getZendeskRequestUrl({ problemType });

  return (
    <ConsultationCardContentView
      paragraphs={
        asyncConsultsEnabled
          ? [
              {
                key: 'awaitingAnswersText0',
                text: (
                  <Typography size="medium-paragraph" isBold>
                    <FormattedMessage
                      defaultMessage="{person} sent you a message."
                      values={{
                        person: doctorName,
                      }}
                    />
                  </Typography>
                ),
              },
              {
                key: 'awaitingAnswersText1',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="Open your consult chat to read it and reply." />
                  </Typography>
                ),
              },
              {
                key: 'awaitingAnswersText2',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage
                      defaultMessage="If you have any questions about your payment, orders or your consult status, reach out to our <a>customer support team</a>."
                      values={{
                        a: (chunks) => (
                          <a href={helpLink} className="text-link">
                            {chunks}
                          </a>
                        ),
                      }}
                    />
                  </Typography>
                ),
              },
              {
                key: 'awaitingAnswersText3',
                text: (
                  <Typography size="medium-paragraph">
                    <FormattedMessage defaultMessage="Please note: Your orders are paused until your consult is complete." />
                  </Typography>
                ),
              },
            ]
          : [
              {
                key: 'awaitingAnswersText',
                text: (
                  <FormattedMessage
                    defaultMessage="{person} has sent you a message."
                    description="Status text informing users someone has sent them a message"
                    values={{
                      person: doctorName,
                    }}
                  />
                ),
              },
            ]
      }
      buttons={[
        {
          key: buttonKey,
          text: asyncConsultsEnabled ? (
            <FormattedMessage defaultMessage="Open consult chat" />
          ) : (
            <FormattedMessage
              defaultMessage="View message"
              description="Button that navigates to message screen"
            />
          ),
          onClick: () => {
            if (hasChatThread) {
              history.push(routes.consultation.chat(consultationId));
            } else {
              history.push(routes.consultation.review(consultationId));
            }
          },
        },
      ]}
    />
  );
};
