import React, { ReactElement } from 'react';
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
  useRouteMatch,
} from 'react-router-dom';
import { CompatRouter } from 'react-router-dom-v5-compat';
import { RouteTracker, ScrollToTop } from '@customer-frontend/route';
import PrivateRoute from 'components/navigation/private-route';
import {
  learnRoutes,
  primaryNavigationRoutes,
  progressTrackerRoutes,
  routes,
  treatmentRoutes,
} from 'utils/routes';
import { NavBarLayout } from 'components/navigation/navbar-layout';
import ProfileTreatment from './profile/treatment';
import { Account } from './profile/account';
import ProgressTracker from './profile/progress';
import Profile from './profile';
import TreatmentLayout from './treatment';
import { ProgressCompareProvider } from './profile/progress/providers';
import { PersistedDiscountFromURLProvider } from '@customer-frontend/order';
import { useFeatureFlagBoolean } from '@customer-frontend/feature-flags';
import { ImpersonatingUserBanner } from '@customer-frontend/auth';

const Login = React.lazy(() => import('./auth/login'));
const Reset = React.lazy(() => import('./auth/reset'));
const ForgetPassword = React.lazy(() => import('./auth/forget-password'));
const Signup = React.lazy(() => import('./auth/signup'));
const Start = React.lazy(() => import('./start/start'));
const StartWelcome = React.lazy(() => import('./start/start-welcome'));
const StartSignup = React.lazy(() => import('./start/start-signup'));
const StartConfirm = React.lazy(() => import('./start/start-confirm'));

const StartEnterFirstName = React.lazy(
  () => import('./start/legacy-email-first/start-enter-first-name'),
);
const StartEnterLastName = React.lazy(
  () => import('./start/legacy-email-first/start-enter-last-name'),
);
const StartProblemType = React.lazy(
  () => import('./start/legacy-email-first/start-problem-type'),
);
const ContinueToProfile = React.lazy(() => import('./start/continue'));
const Quiz = React.lazy(() => import('./quiz/quiz'));
const QuizComplete = React.lazy(() => import('./quiz/quiz-complete'));
const QuizStartGeneral = React.lazy(() => import('./quiz/quiz-start-general'));
const QuizCompleteGeneral = React.lazy(
  () => import('./quiz/quiz-complete-general'),
);

const ConsultationPayment = React.lazy(() => import('./consultation/payment'));
const ConsultationProfile = React.lazy(
  () => import('./consultation/prepayment-experience/consultation-profile'),
);
const ConsultationConfirmation = React.lazy(
  () => import('./consultation/confirmation'),
);
const PractitionerPhoneCall = React.lazy(
  () => import('./consultation/practitioner-phone-call'),
);
const SchedulePhoneCall = React.lazy(
  () => import('./consultation/schedule-phone-call'),
);
const ConsultationChat = React.lazy(() => import('./consultation/chat'));

const OrderConfirmation = React.lazy(() => import('./consultation/complete'));
const ConsultationPlan = React.lazy(() => import('./consultation/plan'));
const ConsultationPlanLegacy = React.lazy(
  () => import('./consultation/plan-legacy'),
);
const DoctorsNote = React.lazy(() => import('./consultation/doctors-note'));
const PharmacySafetyInformation = React.lazy(
  () => import('./consultation/pharmacy-safety-information'),
);
const PharmacySafetyInformationLegacy = React.lazy(
  () => import('./consultation/pharmacy-safety-information-legacy'),
);
const Regimen = React.lazy(() => import('./consultation/regimen'));
const Shipping = React.lazy(() => import('./consultation/shipping'));
const Reason = React.lazy(() => import('./consultation/reason'));
const Review = React.lazy(() => import('./consultation/review'));
const Learn = React.lazy(() => import('./learn'));
const LearnArticle = React.lazy(() => import('./learn/article'));

const Treatments = React.lazy(() => import('./treatment/overview'));
const TreatmentPlan = React.lazy(() => import('./treatment/plan'));

const TreatmentNotes = React.lazy(() => import('./treatment/notes'));
const DiscoverProducts = React.lazy(() => import('./treatment/products'));
const FollowUp = React.lazy(() => import('./consultation/follow-up'));
const ConfirmPathology = React.lazy(
  () => import('./consultation/confirm-pathology'),
);
const QuizSummary = React.lazy(() => import('./quiz/quiz-summary'));
const Error = React.lazy(() => import('./error'));

const ProgressCompareStart = React.lazy(
  () => import('./profile/progress/compare'),
);
const ProgressCompareBefore = React.lazy(
  () => import('./profile/progress/compare/before-photo'),
);
const ProgressCompareAfter = React.lazy(
  () => import('./profile/progress/compare/after-photo'),
);
const ProgressCompareDone = React.lazy(
  () => import('./profile/progress/compare/done'),
);

const CollectMedicare = React.lazy(
  () => import('./consultation/collect-medicare'),
);

const CollectAddress = React.lazy(
  () => import('./consultation/collect-address'),
);

const ConfirmAnswers = React.lazy(
  () => import('./consultation/confirm-answers'),
);

const Support = React.lazy(() => import('./profile/support'));

const CreateAccount = React.lazy(() => import('./consultation/create-account'));

const CheckinQuiz = React.lazy(() => import('./quiz/check-in'));
const ConsultPay = React.lazy(() => import('./consultation/consult-pay'));

const CallConfirm = React.lazy(
  () => import('./consultation/practitioner-call-confirmed'),
);
const FurConsultChoice = React.lazy(
  () => import('./consultation/fur-consult-choice'),
);
const AsyncConsultConfirmed = React.lazy(
  () => import('./consultation/async-consult-confirmed'),
);

function Routes(): ReactElement {
  return (
    <BrowserRouter>
      <CompatRouter>
        <RouteTracker />
        <ScrollToTop />
        <ImpersonatingUserBanner />
        <PersistedDiscountFromURLProvider>
          <NavBarLayout>
            <Switch>
              <Route path={routes.login}>
                <Login />
              </Route>
              <Route path={routes.forgotPassword}>
                <ForgetPassword />
              </Route>
              <Route path={routes.reset}>
                <Reset />
              </Route>
              <Route path={routes.signup}>
                <Signup />
              </Route>
              <Route path={routes.start} exact>
                <Start />
              </Route>
              <Route path={routes.startWelcome}>
                <StartWelcome />
              </Route>
              <Route path={routes.startSignup}>
                <StartSignup />
              </Route>
              <PrivateRoute path={routes.startConfirm}>
                <StartConfirm />
              </PrivateRoute>
              <PrivateRoute path={routes.startEnterFirstName}>
                <StartEnterFirstName />
              </PrivateRoute>
              <PrivateRoute path={routes.startEnterLastName}>
                <StartEnterLastName />
              </PrivateRoute>
              <PrivateRoute path={routes.startProblemType}>
                <StartProblemType />
              </PrivateRoute>
              <PrivateRoute path={routes.continueToProfile}>
                <ContinueToProfile />
              </PrivateRoute>
              <PrivateRoute path="/treatment/:treatmentId">
                <TreatmentRoutes />
              </PrivateRoute>
              <PrivateRoute
                path={primaryNavigationRoutes.account}
                component={Account}
              />
              <PrivateRoute
                path={[
                  ...Object.values(primaryNavigationRoutes),
                  ...Object.values(treatmentRoutes),
                ]}
              >
                <HomeRoutes />
              </PrivateRoute>
              <PrivateRoute exact path={learnRoutes.root} component={Learn} />
              <PrivateRoute
                path={`${learnRoutes.root}/:articleId`}
                component={LearnArticle}
              />
              <PrivateRoute
                path={progressTrackerRoutes.root}
                component={ProgressTrackerRoutes}
              />
              <PrivateRoute path={'/check-in'}>
                <CheckinQuiz />
              </PrivateRoute>
              <PrivateRoute path="/quiz">
                <QuizRoutes />
              </PrivateRoute>
              <PrivateRoute path="/consultation/:consultationId">
                <ConsultationRoutes />
              </PrivateRoute>
              <PrivateRoute path="/call/:pracBookingId/confirmation">
                <CallConfirm />
              </PrivateRoute>
              <Route path={routes.profile}>
                <Redirect to={primaryNavigationRoutes.home} />
              </Route>
              <Route path="/" exact>
                <Redirect
                  to={{
                    pathname: primaryNavigationRoutes.home,
                  }}
                />
              </Route>
              <Route>
                <Error />
              </Route>
            </Switch>
          </NavBarLayout>
        </PersistedDiscountFromURLProvider>
      </CompatRouter>
    </BrowserRouter>
  );
}

function HomeRoutes(): React.ReactElement {
  return (
    <Profile>
      <Switch>
        <Route
          path={primaryNavigationRoutes.home}
          component={ProfileTreatment}
        />
        <Route
          exact
          path={primaryNavigationRoutes.treatment}
          component={Treatments}
        />
        <Route
          path={primaryNavigationRoutes.discover}
          component={DiscoverProducts}
        />
        <Route path={primaryNavigationRoutes.support} component={Support} />
        <Route>
          <Redirect
            to={{
              pathname: primaryNavigationRoutes.home,
            }}
          />
        </Route>
      </Switch>
    </Profile>
  );
}

function TreatmentRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <TreatmentLayout>
      <Switch>
        <Route path={treatmentRoutes.plan}>
          <TreatmentPlan inPrimaryNav={false} />
        </Route>
        <Route exact path={`${path}/notes`} component={TreatmentNotes} />
      </Switch>
    </TreatmentLayout>
  );
}

function ProgressTrackerRoutes(): ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}`} component={ProgressTracker} />
      <Route path={progressTrackerRoutes.compare}>
        <ProgressCompareProvider>
          <Route
            exact
            path={progressTrackerRoutes.compare}
            component={ProgressCompareStart}
          />
          <Route
            exact
            path={`${progressTrackerRoutes.compare}/before`}
            component={ProgressCompareBefore}
          />
          <Route
            exact
            path={`${progressTrackerRoutes.compare}/after`}
            component={ProgressCompareAfter}
          />
          <Route
            exact
            path={`${progressTrackerRoutes.compare}/done`}
            component={ProgressCompareDone}
          />
        </ProgressCompareProvider>
      </Route>
    </Switch>
  );
}

function ConsultationRoutes(): React.ReactElement {
  const { path } = useRouteMatch();
  const isSplitPlanFlow = useFeatureFlagBoolean(
    'FF_CONSULTATION_ORDER_PAYMENT_SPLIT_FLOW',
  );

  return (
    <Switch>
      <Route path={`${path}/consult-pay`}>
        <ConsultPay />
      </Route>
      <Route path={`${path}/confirm-answers`}>
        <ConfirmAnswers />
      </Route>
      <Route path={`${path}/create-account`}>
        <CreateAccount />
      </Route>
      <Route path={`${path}/consultationProfile`}>
        <ConsultationProfile />
      </Route>
      <Route path={`${path}/payment`}>
        <ConsultationPayment />
      </Route>
      <Route path={`${path}/confirmation`}>
        <ConsultationConfirmation />
      </Route>
      <Route path={`${path}/fur-consult-choice`}>
        <FurConsultChoice />
      </Route>
      <Route path={`${path}/phone-call`}>
        <PractitionerPhoneCall />
      </Route>
      <PrivateRoute path={`${path}/schedule-call`}>
        <SchedulePhoneCall />
      </PrivateRoute>
      <Route path={`${path}/async-confirmed`}>
        <AsyncConsultConfirmed />
      </Route>
      <PrivateRoute path={`${path}/chat`}>
        <ConsultationChat />
      </PrivateRoute>
      <Route path={`${path}/complete`}>
        <OrderConfirmation />
      </Route>
      <Route path={`${path}/plan`}>
        {isSplitPlanFlow ? <ConsultationPlan /> : <ConsultationPlanLegacy />}
      </Route>
      <PrivateRoute path={`${path}/doctors-note`}>
        <DoctorsNote />
      </PrivateRoute>
      <Route path={`${path}/pharmacyInformation`}>
        {isSplitPlanFlow ? (
          <PharmacySafetyInformation />
        ) : (
          <PharmacySafetyInformationLegacy />
        )}
      </Route>
      <Route path={`${path}/regimen`}>
        <Regimen />
      </Route>
      <Route path={`${path}/shipping`}>
        <Shipping />
      </Route>
      <Route path={`${path}/reason`}>
        <Reason />
      </Route>
      <Route path={`${path}/review`}>
        <Review />
      </Route>
      <PrivateRoute path={`${path}/follow-up`}>
        <FollowUp />
      </PrivateRoute>
      <PrivateRoute path={`${path}/collect-medicare`}>
        <CollectMedicare />
      </PrivateRoute>
      <PrivateRoute path={`${path}/collect-address`}>
        <CollectAddress />
      </PrivateRoute>
      <PrivateRoute path={`${path}/confirm-pathology`}>
        <ConfirmPathology />
      </PrivateRoute>
      <PrivateRoute exact path={path}>
        <Redirect to={routes.profile} />
      </PrivateRoute>
    </Switch>
  );
}

function QuizRoutes(): React.ReactElement {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/start/:quizCode`}>
        <QuizStartGeneral />
      </Route>
      <Route path={`${path}/complete/:quizCode`}>
        <QuizCompleteGeneral />
      </Route>
      <Route path={`${path}/:quizApplicationId/complete`}>
        <QuizComplete />
      </Route>
      <Route path={`${path}/:quizApplicationId/summary`}>
        <QuizSummary />
      </Route>
      <Route
        path={[
          `${path}/:quizApplicationId/question/:questionId`,
          `${path}/:quizApplicationId`,
        ]}
      >
        <Quiz />
      </Route>
    </Switch>
  );
}

export default Routes;
